// Generated by Framer (334e254)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VKLSpPA4A"];

const serializationHash = "framer-5L6kW"

const variantClassNames = {VKLSpPA4A: "framer-v-fpmri6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VKLSpPA4A", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-fpmri6", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"VKLSpPA4A"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 3, borderBottomRightRadius: 3, borderTopLeftRadius: 3, borderTopRightRadius: 3, ...style}}><SVG className={"framer-le7l32"} data-framer-name={"Group 6912"} layout={"position"} layoutDependency={layoutDependency} layoutId={"zCm31m8vF"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 5.5 20 C 2.462 20 0 20 0 20 L 0 0 C 0 0 2.462 0 5.5 0 L 14.5 0 C 17.538 0 20 0 20 0 L 20 20 C 20 20 17.538 20 14.5 20 Z\" fill=\"rgb(58,196,147)\"></path><path d=\"M 15.53 6.25 C 15.53 6.25 13.604 8.214 11.679 10.179 C 9.753 12.143 7.827 14.108 7.827 14.108 L 4 10.28 L 5.061 9.22 L 7.734 11.893 L 14.384 5.283 Z\" fill=\"rgb(0, 0, 0)\"></path></svg>"} svgContentId={165329110} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-5L6kW[data-border=\"true\"]::after, .framer-5L6kW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5L6kW.framer-1q58zwt, .framer-5L6kW .framer-1q58zwt { display: block; }", ".framer-5L6kW.framer-fpmri6 { height: 20px; overflow: hidden; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-5L6kW .framer-le7l32 { flex: none; height: 20px; left: calc(50.00000000000002% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 20px / 2); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerzYJ9bBXhS: React.ComponentType<Props> = withCSS(Component, css, "framer-5L6kW") as typeof Component;
export default FramerzYJ9bBXhS;

FramerzYJ9bBXhS.displayName = "Check";

FramerzYJ9bBXhS.defaultProps = {height: 20, width: 20};

addFonts(FramerzYJ9bBXhS, [])